import React, { FC } from "react";
import { ButtonBack, ButtonNext } from "pure-react-carousel";
import buttonArrowLeft from "../../../../images/buttonArrowLeft.svg";
import buttonArrowRight from "../../../../images/buttonArrowRight.svg";

type Props = {
  sliderButtonsClassName: string;
};

const CaseStudiesSliderButtons: FC<Props> = ({ sliderButtonsClassName }) => {
  return (
    <div className={sliderButtonsClassName}>
      <ButtonBack>
        <img src={buttonArrowLeft} alt="Last" />
      </ButtonBack>
      <ButtonNext>
        <img src={buttonArrowRight} alt="Next" />
      </ButtonNext>
    </div>
  );
};

export default CaseStudiesSliderButtons;
